import React from 'react';
import BingeItemEpisode from './BingeItemEpisode';


export default function EpisodeList({ episodes, isSeason, seriesActive, seasonActive, toggleItem }) {

    return (
      <ul className="episode-list">
        {episodes.map((ep, epIndex) => {
          return (
            <li key={ep.id} className={`episode-list__item`} data-status={ ep.active ? 'active' : 'disabled' }>  
                  <BingeItemEpisode ep={ ep } isSeason={ isSeason } seriesActive={ seriesActive } seasonActive={ seasonActive } index={ epIndex } toggleItem={ toggleItem } />
            </li>
          )
        })}
      </ul>
    );
  }
  