import React from 'react';
import BingeItemHeader from './BingeItemHeader';
import BingeItemContent from './BingeItemContent';
import BingeItemDetails from './BingeItemDetails';
import SeasonList from './SeasonList';
import EpisodeList from './EpisodeList';

export default function BingeItem({ item, index, toggleItem }) {

    return (
    <div className="binge-item-container">

      <BingeItemHeader index={index} id={item.id} active={item.active} type={item.type} runtime={item.runtime} toggleItem={toggleItem} />
      <BingeItemContent item={item} />
      <BingeItemDetails genre={item.genre} writer={item.writer} actors={item.actors} plot={item.plot} />

      {item.seasons ? <SeasonList item={item} toggleItem={toggleItem} /> : ''}
      {item.episodes ? <EpisodeList episodes={item.episodes} isSeason={false} seriesActive={item.active} seasonActive={ true } toggleItem={ toggleItem } /> : ''}
     
    </div>
    );

}
  