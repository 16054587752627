import React from 'react';


export default function BingeItemDetails({genre, director, writer, actors, plot}) {

    return (
      <div className="binge-item-details" data-status="inactive">
          <dl>
            <dt>Genre:</dt> <dd>{genre.join(', ')}</dd>
            {director ? <><dt>Director:</dt> <dd>{director.join(', ')}</dd></> : ''}
            <dt>Writer:</dt> <dd>{writer.join(', ')}</dd>
            <dt>Actors:</dt> <dd>{actors.join(', ')}</dd>
            <dt>Plot:</dt> <dd>{plot}</dd>
          </dl>
      </div>
    );
  }
  