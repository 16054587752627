import React from 'react';
import EpisodeList from './EpisodeList';
import formatTime from '../utils/formatTime';


export default function SeasonList({ item, toggleItem }) {

  const seeEpisodes = (e) => {

    var closestLi = e.target.closest('.season-list__item');

    if (closestLi.dataset.status === 'active') {

      var episodesBox = e.target.parentNode.parentNode.nextSibling;
      if (episodesBox.dataset.status === 'active') {
        episodesBox.dataset.status = 'inactive';
        e.target.innerText = 'See Episodes';
      } else {
        episodesBox.dataset.status = 'active';
        e.target.innerText = 'Hide Episodes';
      }

    }

  }

    return (
      <div className="season-list">
      {item.seasons?.map(({season, episodes, active, runtime}, seasonIndex) => {
         return (
          <div key={ seasonIndex } className="season-list__item" data-status={active ? 'active' : 'disabled'}>

            <div className="season-list__title">
              <label className="form-switch">
                <input type="checkbox" name={ `season-${season}` } onChange={(e) => toggleItem('season', e)} disabled={ !item.active } checked={ active } />
                <i></i>
              </label>
              <h3><span>Season {season}</span> <button type="button" className="btn-see-episodes" onClick={(e) => seeEpisodes(e)}>See Episodes</button></h3>
              
              <div className="season-list__runtime" dangerouslySetInnerHTML={{__html: formatTime(runtime)}}></div>
            </div>

            <div className="season-list__episode-list" data-status="inactive">
              <EpisodeList episodes={episodes} isSeason={true} seriesActive={item.active} seasonActive={active} toggleItem={toggleItem} />
            </div>
          </div>
         )
      })}
      </div>
    );
  }
  