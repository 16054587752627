import React from 'react';
import Img from 'gatsby-image';
import formatTime from '../utils/formatTime';


export default function BingeHeader({name, breakdown, duration, dayMax, timeframe, image}) {

    return (
      <div className="binge-header">
        <h1>{name}</h1>
        <div className="binge-header__breakdown">{ breakdown }</div>
        <div className="binge-header__duration" dangerouslySetInnerHTML={{__html: formatTime(duration)}}></div>
        <div className="binge-header__timeframe">
          <p>Up to {dayMax} min/day end date: {timeframe}</p>
        </div>
        <Img className="binge-header__image" fluid={image} alt={name} />
      </div>
    );
  }
  