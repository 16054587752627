import React from 'react';
import Img from 'gatsby-image';


export default function BingeItemContent({item}) {

  const seeDetails = (e) => {

    var closestLi = e.target.closest('li');

    if (closestLi.dataset.status === 'active') {

      var detailsBox = e.target.parentNode.parentNode.nextSibling;
      if (detailsBox.dataset.status === 'active') {
        detailsBox.dataset.status = 'inactive';
        e.target.innerText = 'See Details';
      } else {
        detailsBox.dataset.status = 'active';
        e.target.innerText = 'Hide Details';
      }

    }
  }

    return (
      <div className="binge-item-content">
        <Img className="binge-item-content__image" fluid={item.childImageSharp?.fluid} />
        <div className="binge-item-content__text">
          <h2>{item.title}</h2>
          <div className="binge-item-content__byline">{item.released} • {item.rated} <div className="binge-item-content__rating-bubble"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245"><path d="m56,237 74-228 74,228L10,96h240"/></svg> {item.imdbrating}</div></div>
          <button className="btn-see-details" type="button" onClick={(e) => seeDetails(e)}>See Details</button>
        </div>
      </div>
    );
  }
  