import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import Helmet from '../components/Helmet';
import BingeItemMovie from '../components/BingeItemMovie';
import BingeItemSeries from '../components/BingeItemSeries';
import BingeHeader from '../components/BingeHeader';
import SectionTitle from '../components/SectionTitle';


export default function SingleBingePage({ data: { binge } }) {
  //console.log(binge.nodes[0]);


  const bingeName = binge.nodes[0].name;
  const bingeSlug = binge.nodes[0].slug;
  const bingeBreakdownDefault = binge.nodes[0].breakdown;
  const bingeDurationDefault = binge.nodes[0].duration;
  const bingeDescription = binge.nodes[0].description;
  const bingeItemsDefault = binge.nodes[0].items;
  const bingeCoverPath = binge.nodes[0].cover;
  const bingeCover = binge.nodes[0].bingeCoverImages.childImageSharp.fluid;


  let hideChrono = false;

  //let currentDate = moment().format('MMMM Do, h:mm a');
  let dayMaxMinutes = 180;


  const [sortType, setSortType] = useState({'type':'chrono','direction':'acs'});
  const [filterType, setFilterType] = useState({'movies': true,'series': true});
  const [bingeList, setBingeList] = useState(bingeItemsDefault);
  const [bingeBreakdown, setBingeBreakdown] = useState(bingeBreakdownDefault);
  const [bingeDuration, setBingeDuration] = useState(bingeDurationDefault);
  //const [endDate, setEndDate] = useState('');
  const [endDateTimeframe, setEndDateTimeframe] = useState('');

  if (binge.nodes[0].hideChrono === true) {
    hideChrono = true;
  }

  const tempBingeList = [...bingeList];

  tempBingeList.forEach(({ poster }, i) => {
      const imageName = poster.split('/').pop();
      //console.log(imageName);
        const result = binge.nodes[0].bingePosterImages.find( ({ childImageSharp }) => childImageSharp.fluid.originalName === imageName );
        Object.assign(tempBingeList[i],result);
      //console.log(result);
      
  });

  useEffect(() => {
    document.querySelector('.view-options > button').dataset.dir = 'acs'; 
  },[]); 

  useEffect(() => {
    if (hideChrono === true) {
      setSortType({'type':'release','direction':'acs'});
    }
  },[hideChrono]); 


  useEffect(() => {

    //console.log(`sortType: ${sortType}`);

    const sortArray = (type, direction) => {

      if (type === 'release') {
        if (direction === 'acs') {
          setBingeList([...bingeList].sort((a, b) => a.year - b.year));
        } else {
          setBingeList([...bingeList].sort((a, b) => b.year - a.year));
        }
      } else if (type === 'chrono') {
        if (direction === 'acs') {
          setBingeList([...bingeList].sort((a, b) => a.chrono - b.chrono));
        } else {
          setBingeList([...bingeList].sort((a, b) => b.chrono - a.chrono));
        }
      } else if (type === 'length') {
        if (direction === 'acs') {
          setBingeList([...bingeList].sort((a, b) => a.runtime - b.runtime));
        } else {
          setBingeList([...bingeList].sort((a, b) => b.runtime - a.runtime));
        }
      } else {
        if (direction === 'acs') {
         setBingeList([...bingeList].sort((a, b) => a.title.localeCompare(b.title)));
        } else {
          setBingeList([...bingeList].sort((a, b) => -1 *  a.title.localeCompare(b.title)));
        }
      }

    };
    sortArray(sortType.type, sortType.direction);
  // eslint-disable-next-line
  },[sortType]);

  useEffect(() => {

    const tempBingeList = [...bingeList];

    tempBingeList.forEach((item) => {
       if (item.type === 'series') {
          item.active = filterType.series;

          item.seasons?.forEach((season) => {
            season.active = filterType.series;

            season.episodes.forEach((ep) => {
              ep.active = filterType.series;
            });
          });

          item.episodes?.forEach((season) => {
            season.active = filterType.series;
          });

       } else {
          item.active = filterType.movies;
       }
    });

   

    setBingeList(tempBingeList);


  // eslint-disable-next-line
  },[filterType]);

  useEffect(() => {

    let bingeAcc = 0;
    let dayCount = 0;

    let breakdownText = '';
    let movieCount = 0;
    let seasonCount = 0;
    let episodeCount = 0;

    [...bingeList].forEach(({active, type, runtime, seasons, episodes}) => {
      if (active && type==='movie') {
        bingeAcc = bingeAcc + runtime;

        movieCount = movieCount + 1;

        if (runtime < dayMaxMinutes) {
          dayCount = dayCount + 1;
        }

      } else if (active && type==='series') {

        seasons?.forEach(({active, episodes}) => {
          if (active) {
            seasonCount = seasonCount + 1;
            episodes?.forEach(({active, runtime}) => {
              if (active) {
                bingeAcc = bingeAcc + runtime;
    
                if (runtime < dayMaxMinutes) {
                  dayCount = dayCount + 1;
                }
              }
            });
          }
        });

        episodes?.forEach(({active, runtime}) => {
          if (active) {
            episodeCount = episodeCount + 1;
            bingeAcc = bingeAcc + runtime;

            if (runtime < dayMaxMinutes) {
              dayCount = dayCount + 1;
            }
          }
        });
         
      }

       //console.log(dayCount);
    });

    let hasMovie = '';

    if (movieCount > 1) {
      breakdownText = movieCount + ' movies';
      hasMovie = ' + ';
    } else if (movieCount > 0){
      breakdownText = movieCount + ' movie';
      hasMovie = ' + ';
    }

    if (seasonCount > 1) {
      breakdownText = breakdownText + hasMovie + seasonCount + ' series seasons';
    } else if (seasonCount > 0){
      breakdownText = breakdownText + hasMovie + seasonCount + ' series season';
    }

    if (episodeCount > 1) {
      breakdownText = breakdownText + hasMovie + episodeCount + ' episodes';
    } else if (episodeCount > 0){
      breakdownText = breakdownText + hasMovie + episodeCount + ' episode';
    }

    setBingeBreakdown(breakdownText);

    setBingeDuration(bingeAcc);
    //setEndDate(moment().add(bingeAcc, 'm').format('MMMM Do, h:mm a'));
    setEndDateTimeframe(moment().add(dayCount, 'd').format('MMMM Do, YYYY'));
    
  },[bingeList, dayMaxMinutes]);

  function getElementIndex(element) {
    if (element) {
      return Array.from(element.parentNode.children).indexOf(element);
    } else {
      return null;
    }
  }

  const toggleItem = (type, e) => {

    const itemIndex = getElementIndex (e.target.closest('.binge-list__item'));
    const checked = e.target.checked;
    const newBingeList = [...bingeList];

    if (type === 'episode') {

      const seasonIndex = getElementIndex(e.target.closest('.season-list__item'));
      const episodeIndex = getElementIndex(e.target.closest('.episode-list__item'));

      console.log(seasonIndex);

      if (seasonIndex !== null) {
        newBingeList[itemIndex].seasons[seasonIndex].episodes[episodeIndex].active = checked;

        let seasonRuntime = 0;

        newBingeList[itemIndex].seasons[seasonIndex].episodes?.forEach(ep => {
          if (ep.active === true) {
            seasonRuntime = seasonRuntime + ep.runtime
          }
        });

        newBingeList[itemIndex].seasons[seasonIndex].runtime = seasonRuntime;

        let seriesRuntime = 0;

        newBingeList[itemIndex].seasons?.forEach(season => {
          if (season.active === true) {
            seriesRuntime = seriesRuntime + season.runtime
          }
        });

        newBingeList[itemIndex].runtime = seriesRuntime;

      } else {
        newBingeList[itemIndex].episodes[episodeIndex].active = checked;

        let seriesRuntime = 0;

        newBingeList[itemIndex].episodes?.forEach(season => {
          if (season.active === true) {
            seriesRuntime = seriesRuntime + season.runtime
          }
        });

        newBingeList[itemIndex].runtime = seriesRuntime;
      }

    } else if (type === 'season') {

      const seasonIndex = getElementIndex (e.target.closest('.season-list__item'));
      newBingeList[itemIndex].seasons[seasonIndex].active = checked;

      let seriesRuntime = 0;

      newBingeList[itemIndex].seasons[seasonIndex].episodes?.forEach(ep => {
        ep.active = checked;
      });

      newBingeList[itemIndex].seasons?.forEach(season => {
        if (season.active === true) {
          seriesRuntime = seriesRuntime + season.runtime
        }
      });

      newBingeList[itemIndex].runtime = seriesRuntime;

    } else if (type === 'series') {

      newBingeList[itemIndex].active = e.target.checked;

      // If seasons of episodes in binge
      newBingeList[itemIndex].seasons?.forEach(season => {
        season.active = checked;

        season.episodes?.forEach(ep => {
          ep.active = checked;
        });
     });

     // If standalone episodes in binge
     newBingeList[itemIndex].episodes?.forEach(ep => {
       ep.active = checked;
     });
      
    } else {
      
      newBingeList[itemIndex].active = e.target.checked;

    }

    //console.log(newBingeList);
    setBingeList(newBingeList);

  };


/*
  const toggleItem = (itemId, itemIndex, type, checked) => {

    const newBingeList = [...bingeList];

    console.log(`itemId:${itemId}, itemIndex:${itemIndex}, type:${type}, checked:${checked}`);

    //console.log(newBingeList);

    if (type === 'series') {
      newBingeList[itemIndex].active = checked;

      let seriesRuntime = checked ? 0 : newBingeList[itemIndex].runtime;

      // If seasons of episodes in binge
      newBingeList[itemIndex].seasons?.forEach(season => {
         season.active = checked;

         season.episodes?.forEach(ep => {
           ep.active = checked;
         });

         if (season.active === true) {
          seriesRuntime = seriesRuntime + season.runtime
        }
      });

      // If standalone episodes in binge
      newBingeList[itemIndex].episodes?.forEach(ep => {
        ep.active = checked;

        if (ep.active === true) {
          seriesRuntime = seriesRuntime + ep.runtime
        }
      });

      newBingeList[itemIndex].runtime = seriesRuntime;

      console.log('series');


    } else if (type === 'season') {


      newBingeList.forEach((item, i) => {

        if (item.type === 'series') {

          let seriesRuntime = 0;

          item.seasons?.forEach((season, j) => {

            let seasonRuntime = checked ? 0 : newBingeList[i]['seasons'][j].runtime;

            console.log(`season.season: ${season.season} | itemId: ${itemId} | itemIndex: ${itemIndex}`);

            if (season.season === itemId) {
              season.active = checked;
              season.episodes?.forEach(ep => {
                ep.active = checked;

                if (ep.active === true) {
                  seasonRuntime = seasonRuntime + ep.runtime
                }

              });
              newBingeList[i]['seasons'][j].runtime = seasonRuntime;
              
            }
            if (season.active === true) {
              seriesRuntime = seriesRuntime + season.runtime
            }
          
          });

          newBingeList[i].runtime = seriesRuntime;

        }

      });

    } else if (type === 'episode') {

      newBingeList.forEach((item, i) => {

        if (item.seasons) {

          let seriesRuntime = 0;

          item.seasons?.forEach((season, j) => {

            let seasonRuntime = 0;

            season.episodes?.forEach(ep => {
              if (ep.id === itemId) {
                ep.active = checked;
              }
              if (ep.active === true) {
                seasonRuntime = seasonRuntime + ep.runtime
              }

              newBingeList[i]['seasons'][j].runtime = seasonRuntime;
            });

            if (season.active === true) {
              seriesRuntime = seriesRuntime + season.runtime
            }
            
          });

          newBingeList[i].runtime = seriesRuntime;

        } else if (item.episodes) {

          let seriesRuntime = 0;

          item.episodes?.forEach(ep => {
              if (ep.id === itemId) {
                ep.active = checked;
              }
              if (ep.active === true) {
                seriesRuntime = seriesRuntime + ep.runtime
              }
          });

          newBingeList[i].runtime = seriesRuntime;

        }

        

      });

    } else {
      // type is movie
      newBingeList[itemIndex].active = checked;
    }

    setBingeList(newBingeList);

  }
*/


  return (
    <main>
      <Helmet title={bingeName} description={bingeDescription} location={bingeSlug} image={bingeCoverPath} />

      <div className="binge-container">

        <BingeHeader name={bingeName} breakdown={bingeBreakdown} duration={bingeDuration} dayMax={dayMaxMinutes} timeframe={endDateTimeframe} image={bingeCover} />
        <SectionTitle title={`Binge List`} style={`secondary`} sortType={sortType} filterType={filterType} setSortType={setSortType} setFilterType={setFilterType} hideChrono={hideChrono} />

        <ul className="binge-list">
            
            {bingeList.map((item, index) => {

              let BingeItemCmp;

              if (item.type === 'series') {
                BingeItemCmp = <BingeItemSeries item={item} index={index} toggleItem={toggleItem} />;
              } else {
                BingeItemCmp = <BingeItemMovie item={item} index={index} toggleItem={toggleItem} />;
              }

              return (
                <li data-id={item.id} data-index={index} key={index} data-status={item.active ? 'active' : 'disabled'} className={`binge-list__item binge-list__item--${item.type}`}>
                    {BingeItemCmp}
                </li>
              )

            })}
            
        </ul>
    
      </div>

      <div className="binge-description">
          <p>{bingeDescription}</p>
      </div>

{/*}
      <div className="total-duration-box">
        <h4>Total Binge Duration:</h4>
        <div className="total-duration-box__duration">{formatTime(bingeDuration)}</div>
      </div>


      <div className="timeframe-box">
        <h4>Start: {currentDate}</h4>
        <div className="timeframe-box__duration">End: {endDate}</div>
      </div>

      <div className="timeframe-box">
        <h4>If you spend up to {dayMaxMinutes} minutes per day</h4>
        <div className="timeframe-box__duration">End: {endDateTimeframe}</div>
      </div>
*/}

    </main>
  );

}


export const query = graphql`
  query($name: String!) {
    binge: allMongodbBingesData(filter: {name: {eq: $name}}) {
      nodes {
        name
        slug
        breakdown
        duration
        description
        hideChrono
        cover
        items {
          active
          actors
          chrono
          director
          genre
          id
          plot
          rated
          released
          runtime
          imdbrating
          poster
          seasons {
            season
            episodes {
              id
              title
              season
              episode
              runtime
              active
            }
            active
            runtime
          }
          title
          type
          writer
          year
          episodes {
            id
            title
            season
            episode
            runtime
            active
          }
        }
        bingeCoverImages {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bingePosterImages {
          childImageSharp {
            fluid(maxWidth: 400) {
              originalName
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

