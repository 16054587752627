import React from 'react';
import BingeItemHeader from './BingeItemHeader';
import BingeItemContent from './BingeItemContent';
import BingeItemDetails from './BingeItemDetails';


export default function BingeItem({ item, index, toggleItem }) {

    return (
      <div className="binge-item-container">
        <BingeItemHeader index={index} id={item.id} active={item.active} type={item.type} runtime={item.runtime} toggleItem={toggleItem} />
        <BingeItemContent item={item} />
        <BingeItemDetails genre={item.genre} director={item.director} writer={item.writer} actors={item.actors} plot={item.plot} />
      </div>
    );
  }
  