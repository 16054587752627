import React from 'react';
import formatTime from '../utils/formatTime';

export default function BingeItemEpisode({ ep, isSeason, seriesActive, seasonActive, index, toggleItem }) {

    return (
    <div className="episode-item">
      <div className="episode-item__content">
        <label className="form-switch small">
          <input type="checkbox" name={ep.id} onChange={(e) => toggleItem('episode', e)} disabled={ !seriesActive || !seasonActive } checked={ ep.active } />
          <i></i>
        </label>
        
        <h4>{ !isSeason ? `S${ep.season}` : '' } E{ep.episode}: {ep.title}</h4>
      </div>

      <div className="episode-item__runtime" dangerouslySetInnerHTML={{__html: formatTime(ep.runtime)}}></div>
    </div>
    );
  }
  